<template>
  <div id="app">
    <div class="page" @click="show = !show">
      <div class="logo"></div>
      <div class="label">
        <div>数字经济人才招聘人才平台</div>
        <div>人才端</div>
      </div>
      <div class="qr" id="qrcode" ref="qrCodeDiv"></div>
      <div class="msg">
        <div>温馨提示:</div>
        <div>
          请事先准备电子简历(PDF)，存于微信“文件传输助手”，扫描此二维码后按提示操作上传简历
        </div>
      </div>
      <div class="down"></div>
    </div>
    <div class="left" v-show="show">
      <div :style="`width:${acticeList.length * 22}vw;height:21vh`">
        <div
          :class="item.id === activeId ? 'item activeItem' : 'item'"
          v-for="item in acticeList"
          :key="item.id"
          @click="changeActiveti(item.id)"
        >
          <div class="tit">本场招聘会活动/专场名称</div>
          <div class="data">{{ item.name }}</div>
          <div class="tit">时间</div>
          <div class="time">开始：{{ item.startTime }}</div>
          <div class="time">结束：{{ item.endTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      acticeList: [],
      idList: [],
      timer: null,
      activeId: "",
      show: false,
    };
  },
  components: {},
  computed: {},
  watch: {},
  created() {},
  activated() {},
  mounted() {
    this.$nextTick(() => {
      this.getList();
      this.timeInterval();
    });
  },

  methods: {
    timeInterval() {
      this.timer = setInterval(() => {
        this.getList(); //你所加载数据的方法
      }, 600000);
    },

    // 点击选择
    changeActiveti(id) {
      this.activeId = id;
      this.qrcodeCreat();
    },

    //   定时获取列表
    getList() {
      let that = this;
      axios
        .post("https://zpgl.ccqdetm.com/api/activity/now", {})
        .then(function (res) {
          that.acticeList = res.data.data;
          if (res.data.data.length > 0) {
            let id = res.data.data[0].id;
            that.idListCreat(res.data.data);
            //   做个判断，是否在列表
            if (that.activeId.length < 1) {
              that.activeId = id;
              that.qrcodeCreat();
            } else {
              if (!that.idList.includes(that.activeId)) {
                that.activeId = id;
                that.qrcodeCreat();
              }
            }
          }
        });
    },

    // 加载二维码
    qrcodeCreat() {
      document.getElementById("qrcode").innerHTML = "";
      new QRCode(this.$refs.qrCodeDiv, {
        text: "https://wxapp.ccqdetm.com/mp/?id=" + this.activeId,
        width: 260,
        height: 250,
        colorDark: "#054080", //二维码颜色
        colorLight: "#fff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.H, //容错率，L/M/H
      });
    },

    //   id 数组
    idListCreat(arr) {
      this.idList = [];
      arr.forEach((item) => {
        this.idList.push(item.id);
      });
    },
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
#app {
  position: relative;
  padding: 0;
  margin: 0;
}
.page {
  height: 100vh;
  width: 100vw;
  background-size: 100% 100%;
  background-image: url("./assets/back.png");
}
.msg,
.label {
  color: #fff;
}
.msg {
  margin-top: 10px;
}
.msg > div {
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.label {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
}
.label > div {
  height: 4vh;
  line-height: 4vh;
}
.qr {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin: 4vh auto;
  height: 250px;
  width: 260px;
}
.left {
  position: absolute;
  overflow-x: auto;
  width: 90vw;
  left: 10vw;
  top: 30vh;
  height: 60vh;

  /* top: calc(50vh - 9vh); */
}

.left .item {
  margin-right: 10px;
  float: left;
  border-radius: 10px;
  background-color: #fff;
  width: 14rem;
  min-height: 13rem;
  padding: 0.5vw;
  margin-bottom: 10px;
}
.activeItem {
  background-color: #ed9494 !important;
}
.activeItem .tit {
  color: #fff;
}
.tit {
  font-size: 1rem;
  color: #7d8082;
  font-weight: 600;
  text-align: center;
  margin: 10px;
}
.data {
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: #107ddd;
}
.time {
  font-family: "黑体";
  font-weight: 600;
  font-size: 1rem;
  color: #107ddd;
  text-align: center;
  height: 1.5rem;
  line-height: 1.5rem;
}
.logo {
  width: 150px;
  height: 150px;
  padding-top: 10px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-image: url("./assets/logo.png");
}
.down {
  position: fixed;
  width: 200px;
  height: 200px;
  bottom: 15px;
  left: calc(50% - 100px);

  background-size: 100% 100%;
  background-image: url("./assets/down.png");
}
</style>